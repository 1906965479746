@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
@font-face {
  font-family: "pfont";
  src: url("/public/911porschav3.ttf") format("truetype"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "pfontbold";
  src: url("/public/911porschav3bold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "pfonttitle";
  src: url("/public/911porschav3title.ttf") format("truetype"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/**** ABOUT ****/

#about-image-container {
  display: flex;
  align-items: center;
  background-color: black;
  width: 900px;
  margin: 0 auto;
  padding-bottom: 40px;
}

#about-image {
  max-width: 50%;
  margin-right: 2rem;
  border-radius: 15px;
}

#about-info {
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
  font-family: "avneir next", sans-serif;
  opacity: 0.9;
}

#about-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  font-family: "pfonttitle";
  font-size: 1.2rem;
  line-height: 1.5;
  margin: auto;
  width: 900px;
  opacity: 0.9;
}

#about-container {
  margin: auto;
  padding-bottom: 10px;
}

#intro-info {
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
  font-family: "avneir next", sans-serif;
  opacity: 0.9;
  margin-right: 20px;
}

#intro-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 900px;
  height: auto;
  margin: 0 auto;
}

#intro-video-gif {
  width: 100%;
  height: auto;
  border-radius: 15px;
  float: right;
}

/**** AUTOMOTIVE****/
#ej22-image {
  width: 400px;
  height: auto;
  margin-right: 20px;
  border-radius: 15px;
}
#ej22-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}
#automotive {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  font-family: "avneir next", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  margin: auto;
  width: 900px;
  opacity: 0.9;
}
/**** CONTACT ****/

#contact {
  position: bottom;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  text-align: center;
  color: white;
  font-family: "avneir next", sans-serif;
  font-size: 1.2rem;
}

#contact-container {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#github-icon {
  width: 56px;
  height: 56px;
  margin-right: 10px;
}

#linkedin-icon {
  width: 50px;
  height: 50px;
  margin-right: 18px;
  margin-top: 3px;
}

#email-icon {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  margin-top: 5px;
}

#contact-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  font-family: "pfonttitle";
  font-size: 1.2rem;
  line-height: 1.5;
  margin: auto;
  width: 900px;
  opacity: 0.9;
}

/**** HOME ****/

body {
  background-color: black;
  margin: 0;
}

#home-image {
  display: block;
  margin: auto;
  border-radius: 15px;
}

#home-image-container {
  padding-bottom: 40px;
  padding-top: 20px;
}

#intro-container {
  padding-top: 20px;
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
  font-family: "avneir next", sans-serif;
  /* opacity: 0.9; */
}

#intro-name {
  font-size: 3.5rem;
  line-height: 1.5;
  color: white;
  /* font-family: "avneir next", sans-serif; */
  /* opacity: 0.9; */
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-family: "pfont";
}

#intro-text {
  font-size: 1.9rem;
  line-height: 1.5;
  color: white;
  font-family: "avneir next", sans-serif;
  /* opacity: 0.9; */
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  font-family: "pfonttitle";
}

#intro-underline {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

/**** NAV ****/

#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: whitesmoke;
  position: sticky;
  top: 0;
  color: black;
  font-family: "avneir next", sans-serif;
  /* font-family: "pfont"; */

  border-bottom: 1px solid black;
  width: 100%;
  animation: fadeIn 1s;
  box-sizing: border-box; /* include border and padding in width */
  /* opacity: 0.9; */
  z-index: 1;
}

.nav-button p:hover {
  text-decoration: none;
  animation: fadeIn 2s;
  color: dimgray;
}
.nav-button {
  margin: 0 1rem;
  cursor: pointer;
}

#button-container {
  display: flex;
  justify-content: center;
  margin-left: auto;
}

#logo p {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "pfontbold";
}

#logo {
  z-index: 999;
  display: flex;
  margin-right: auto;
}

#nav-image {
  height: 10%;
  width: 10%;
  margin-top: -7px;
  margin-right: 10px;
}

/**** PROJECTS ****/

img {
  animation: fadeIn 1s;
}

#projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: white;
  font-family: "avneir next", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  margin: auto;
  width: 900px;
  opacity: 0.9;
}

#projects-title {
  font-family: "pfonttitle";
}

#westy-rentals-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

#westy-rentals-gif {
  width: 400px;
  height: auto;
  margin-right: 20px;
  border-radius: 15px;
}

#westy-rentals-container > div {
  flex: 1;
}

#synth-swap-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

#synth-swap-image {
  width: 400px;
  height: auto;
  margin-right: 20px;
  border-radius: 15px;
}

#synth-swap-container > div {
  flex: 1;
}

#synth-swap-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

#synth-swap-link p:hover {
  text-decoration: none;
  animation: fadeIn 1s forwards;
  color: lightgray;
}

#synth-swap-repo {
  color: white;
  text-decoration: none;
  font-weight: bold;
}
#synth-swap-repo p:hover {
  text-decoration: none;
  animation: fadeIn 1s forwards;
  color: lightgrey;
}
#westfalia-rentals-repo {
  color: white;
  text-decoration: none;
  font-weight: bold;
}
#westfalia-rentals-repo p:hover {
  text-decoration: none;
  animation: fadeIn 1s forwards;
  color: lightgrey;
}

/**** ADJUST FOR MOBILE ****/

@media screen and (max-width: 767px) {
  /* Apply the scaleUp animation to elements */
  .scale-up {
    animation-name: scaleUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  /* Apply the fadeIn animation to elements */
  .fade-in {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  ::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
  body {
    overflow-x: hidden;
  }
  /* Override the max-width property for the about-image-container element */

  /**** MOBILE ABOUT ****/

  /* Adjust the margin and font size for the about-info and intro-info elements */
  #about-image-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #about-info {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin: 2%;
  }

  #about-image {
    order: -1; /* Move the image to the beginning */
    max-width: 100%;
    margin: 2%;
  }

  #about-container {
    width: 100%;
  }

  /**** MOBILE AUTOMOTIVE ****/

  /* Adjust the width for the projects and westy-rentals-gif elements */
  #automotive {
    width: 100%;
  }

  #ej22-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;
  }
  #ej22-image {
    width: 96%;
  }

  /**** MOBILE CONTACT ****/

  /* Adjust the margin and padding for the contact and contact-container elements */
  #contact {
    padding: 1rem;
  }
  #contact-container {
    flex-direction: row;
    align-items: center;
  }
  #linkedin-icon {
    width: 50px;
    height: 50px;
    margin-right: 7px;
    margin-top: 2px;
  }
  #github-icon {
    margin-right: 0;
  }
  #email-icon {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    margin-top: 2px;
  }

  #intro-video-container {
    display: none;
  }
  /**** MOBILE HOME ****/

  #home-image {
    display: none;
  }
  #home-image-container {
    display: none;
  }

  #intro-container {
    padding-top: 10px;
    font-size: 1rem;
    line-height: 1.2;
    color: white;
    font-family: "avneir next", sans-serif;
    opacity: 0.9;
  }

  #intro-name {
    font-size: 2.4rem;
    line-height: 1.2;
    color: white;
    /* font-family: "avneir next", sans-serif; */
    font-family: "pfont";
    opacity: 0.9;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    align-items: center;
  }

  #intro-text {
    display: none;
  }

  #intro-underline {
    display: none;
  }

  /**** MOBILE NAV ****/

  #nav {
    width: 100%;
  }

  #logo {
    display: none;
  }

  #button-container {
    display: flex;
    justify-content: center;
    margin: auto;
  }

  /**** MOBILE PROJECTS ****/

  /* Adjust the width for the projects and westy-rentals-gif elements */
  #projects {
    width: 100%;
  }
  #westy-rentals-gif {
    width: 100%;
  }
  #westy-rentals-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;
  }
  #synth-swap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;
  }
  #synth-swap-image {
    width: 96%;
  }
}
